import request from '@/utils/request'

export function liveRecordDetail(params) {
    return request({
        url: 'curriculum/curriculumDetail',
        method: 'get',
        params
    })
}

export function curriculumBagList(params) {
    return request({
        url: 'curriculum/curriculumBag',
        method: 'get',
        params
    })
}
export function signUp(data) {
    return request({
        url: 'order/createOrder',
        method: 'post',
        data
    })
}
export function getVideoUrl(params) {
    return request({
        url: 'curriculum/recordedBroadcast',
        method: 'get',
        params
    })
}
export function getVideoConfig(params) {
    return request({
        url: 'home/schoolConfig',
        method: 'get',
        params
    })
}
export function pay(params) {
    return request({
        url: 'pay/scanPay',
        method: 'get',
        params
    })
}
export function getPayResult(params) {
    return request({
        url: 'pay/payResult',
        method: 'get',
        params
    })
}
export function setPlayTime(data) {
    return request({
        url: 'curriculum/addPlayRecord',
        method: 'post',
        data
    })
}