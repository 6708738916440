import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import '@/assets/icon/iconfont.css' // a modern alternative to CSS resets
import '@/styles/index.scss' // global css
import 'lib-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import VueI18n from 'vue-i18n'
import i18n from './lang'
Vue.use(VueI18n);
Vue.use(ElementUI);
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
