import request from '@/utils/request'

export function login(data) {
    return request({
        url: 'users/loginByPhone',
        method: 'post',
        data
    })
}
export function scanLogin(data) {
    return request({
        url: 'users/scanlogin',
        method: 'post',
        data
    })
}
export function getLoginSmsCode(data) {
    return request({
        url: 'sms/loginSms',
        method: 'post',
        data
    })
}
export function createWxUserByScan(data) {
    return request({
        url: 'users/createWxUserByScan',
        method: 'post',
        data
    })
}
export function getRegisterSmsCode(data) {
    return request({
        url: 'sms/bindPhoneSms',
        method: 'post',
        data
    })
}
export function subjectList(data) {
    return request({
        url: 'home/recommendSubject',
        method: 'post',
        data
    })
}