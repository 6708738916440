import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/404',
        component: () =>
            import('@/views/error-page/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () =>
            import('@/views/error-page/401'),
        hidden: true
    },
    {
        path: '/',
        component: Layout,
        children: [{
            path: '',
            component: () => import('@/views/index'),
            name: 'Index',
            meta: {
                title: '首页',
            }
        },
        ]
    },
    {
        path: '/list',
        component: Layout,
        children: [{
            path: '',
            component: () => import('@/views/list'),
            name: 'List',
            meta: {
                title: '列表页',
            }
        },
        ]
    },
    {
        path: '/liveDetail',
        component: Layout,
        children: [{
            path: '',
            component: () => import('@/views/liveDetail'),
            name: 'LiveDetail',
            meta: {
                title: '直播详情',
            }
        },
        ]
    },
    {
        path: '/liveRecordDetail',
        component: Layout,
        children: [{
            path: '',
            component: () => import('@/views/liveRecordDetail'),
            name: 'LiveRecordDetail',
            meta: {
                title: '录播详情',
            }
        },
        ]
    },
    {
        path: '/my',
        component: Layout,
        children: [{
            path: '',
            component: () => import('@/views/my'),
            name: 'My',
            meta: {
                title: '我的',
            }
        },
        ]
    },
    {
        path: '/examIng',
        component: () => import('@/views/examIng'),
        name: 'ExamIng',
        meta: {
            title: '考试',
        }
    },
    {
        path: '/examDetail',
        component: Layout,
        children: [{
            path: '',
            component: () => import('@/views/examDetail'),
            name: 'ExamDetail',
            meta: {
                title: '考试详情',
            }
        },
        ]
    },
    {
        path: '/newDetail',
        component: Layout,
        children: [{
            path: '',
            component: () => import('@/views/newDetail'),
            name: 'NewDetail',
            meta: {
                title: '资讯详情',
            }
        },
        ]
    }
]

const createRouter = () => new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({
        y: 0
    }),
    routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
