import axios from 'axios'
import {
    MessageBox,
    Message
} from 'element-ui'
import {
    getToken,
    removeToken
} from '@/utils/auth'
import store from '@/store'
// 获取地址栏参数
const getUrlParams = (url) => {
    let o = {};
    if (url.indexOf("?") != -1) {
        let str = url.substr(url.indexOf("?") + 1).replace(/[#/|/#/]/g, "");
        // console.log(str);
        let strs = str.split("&");
        // console.log(strs);
        for (let i = 0; i < strs.length; i++) {
            o[strs[i].split("=")[0]] = decodeURIComponent(strs[i].split("=")[1]);
        }
    }
    return o;
}
let params = getUrlParams(window.location.href);
if (params && params.schoolId) {
    sessionStorage.setItem("schoolId", params.schoolId);
    sessionStorage.setItem("requestSchoolId", params.schoolId);
} else {
    sessionStorage.setItem("schoolId", sessionStorage.getItem('schoolId')?sessionStorage.getItem('schoolId'):6);
    sessionStorage.setItem("requestSchoolId", sessionStorage.getItem('requestSchoolId')?sessionStorage.getItem('requestSchoolId'):6);
}

const schoolId = sessionStorage.getItem('requestSchoolId');

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        let obj = {};
        obj = {
            schoolId: schoolId
        }
        if (config.method == 'get') {
            config.params = Object.assign({}, config.params || {}, obj)
        } else if (config.method == 'post') {
            config.data = Object.assign({}, config.data, obj)
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // console.log(res);
        // if the custom code is not 20000, it is judged as an error.
        if (res.status == 401 && (res.message == '找不到当前登录的信息')) {
            removeToken();
            window.location.replace(window.location.origin)
        } else if (res.status != -1 && res.status != 99) {
            Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                // to re-login
                MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
                    confirmButtonText: 'Re-Login',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service