<template>
  <div id="layout">
    <comp-header-pc></comp-header-pc>
    <div id="appMain">
      <app-main />
    </div>
    <comp-footer-pc></comp-footer-pc>
    <comp-login-modal></comp-login-modal>
  </div>
</template>

<script>
import ResizeMixin from './mixin/ResizeHandler'
import CompHeaderPc from "@/components/CompHeaderPc.vue";
import CompFooterPc from "@/components/CompFooterPc.vue";
import CompLoginModal from "@/components/CompLoginModal.vue";
import { AppMain } from "./components";
import {
  getVideoConfig,
} from "@/api/live/liveRecordDetail";
export default {
  name: "Layout",
  components: {
    CompHeaderPc,
    AppMain,
    CompFooterPc,
    CompLoginModal
  },
  mixins: [ResizeMixin],
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  created () {
    this.onGetVideoConfig();
  },
  methods: {
    // 获取视频配置信息
    onGetVideoConfig() {
      return new Promise((resolve, reject) => {
        getVideoConfig().then((res) => {
          res.data.colors = res.data.colors?res.data.colors:"#a60002"
          this.$store.dispatch("public/setSchoolInfo", res.data);
          resolve(res.data);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#layout {
  width: 100%;
  background-color: #f4f4f4;
  #appMain {
    width: 100%;
    margin-top: 76px;
    background-color: #f4f4f4;
  }
}
</style>