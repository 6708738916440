<template>
  <div id="footer" class="boxC">
    <div class="footer boxS">
      <div class="left box">
        <div class="logo">
          <img :src="yl9||logoImg" alt="" />
        </div>
        <div class="left_list">
          <div class="left_list_row left_list_row1 box">
            <div
              class="left_list_item"
              v-for="(item, index) in yl1"
              :key="'yl1' + index"
            >
              <a :href="item.url" target="_blank">{{ item.name }}</a>
            </div>
          </div>
          <div class="left_list_row box">
            {{yl2||"Copyright@广州鸿日教育投资咨询集团有限公司 www.hrgcn.com　版权所有"}}
          </div>
          <div class="left_list_row box">
            {{yl3||'广州鸿日教育投资咨询集团有限公司版权所有'}}
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="margin-left:5px;"
              > {{yl4||'粤ICP备2023142788号-1'}}</a
            >
          </div>
          <div class="left_list_row box">联系电话：{{yl5||'15622255420'}}</div>
        </div>
      </div>
      <div class="right box">
        <div class="right_item boxC">
          <img :src="yl6||weChatCodeImg" alt="" width="100%" height="100%" />
          <span>关注微信小程序</span>
        </div>
        <div class="right_item boxC">
          <img :src="yl7||qRCodeImg" alt="" width="100%" height="100%" />
          <span>关注微信公众号</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoImg from "@/assets/common/footerLogo.png";
import WeChatCodeImg from "@/assets/common/wechatCode.jpg";
import QRCodeImg from "@/assets/common/qrCode.jpg";
import { getVideoConfig } from "@/api/live/liveRecordDetail";
export default {
  name: "Footer",
  data() {
    return {
      logoImg: LogoImg,
      weChatCodeImg: WeChatCodeImg,
      qRCodeImg: QRCodeImg,
      yl1: [],
      yl2: "",
      yl3: "",
      yl4: "",
      yl5: "",
      yl6: "",
      yl7: "",
      yl9: "",
    };
  },
  created() {
    this.onGetVideoConfig();
  },
  methods: {
    // 获取视频配置信息
    onGetVideoConfig() {
      return new Promise((resolve, reject) => {
        getVideoConfig().then((res) => {
          this.yl1 = res.data.yl1 ? JSON.parse(res.data.yl1) : [];
          this.yl2 = res.data.yl2;
          this.yl3 = res.data.yl3;
          this.yl4 = res.data.yl4;
          this.yl5 = res.data.yl5;
          this.yl6 = res.data.yl6;
          this.yl7 = res.data.yl7;
          this.yl9 = res.data.yl9;
          resolve(res.data);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#footer {
  width: 100%;
  height: 160px;
  background-color: #e1e1e1;
  .footer {
    width: 1200px;
    height: 100%;
    .left {
      padding-top: 30px;
      padding-bottom: 34px;
      align-items: flex-start;
      .logo {
        width: 240px;
      }
      .left_list {
        height: 100%;
        margin-left: 14px;
        padding-left: 24px;
        border-left: 1px solid #ccc;
      }
      .left_list_row {
        font-size: 12px;
        color: #666;
        line-height: 24px;
        .left_list_item {
          padding-right: 6px;
          margin-right: 6px;
          border-right: 1px solid #333;
          font-size: 12px;
          color: #333;
          line-height: 1;
          cursor: pointer;
        }
        .left_list_item:last-child {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }
      }
      .left_list_row1 {
        margin-bottom: 8px;
      }
    }
    .right {
      .right_item {
        margin-left: 20px;
        flex-direction: column;
        img {
          width: 90px;
          height: 90px;
        }
        span {
          margin-top: 6px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
