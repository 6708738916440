// en.js
import enLocale from 'element-ui/lib/locale/lang/en'   //引入element语言包
export default {
  public: {
    placeholderText: "请输入内容",
    placeholderTextByPhone: "请输入手机号",
    priceIcon: "￥",
    emptyText: "暂无数据",
    signUpText: "人报名",
    noRegistrationText: "暂无报名",
    filterAllText: "全部",
    teachingMethodTitle0: "直播",
    teachingMethodTitle1: "录播",
    teachingMethodTitle2: "小班课",
    notifySuccess: "操作成功",
    confirmText: "确定",
    saveText: "保存",
    changeText: "修改",
    cancelText: "取消",
    tipText: "提示"
  },
  header: {
    loginBtnText: "登录",
    registerBtnText: "注册",
    myClassText: "我的课程",
    myExamText: "我的考试",
    settingText: "设置"
  },
  login: {
    title1: "欢迎来到红海云课堂",
    loginTypeTitle1: "请使用微信扫码登录",
    loginTypeTitle2: "手机验证码登录",
    placeholderTextByImgCode: "图片验证码",
    placeholderTextBySmsCode: "短信验证码",
    loginBtn: "登录",
    cancelLoginBtn: "暂不登录",
    loginTypeText1: "手机验证码登录",
    loginTypeText2: "请使用微信扫码登录",
    updateImageCodeText: "换一个",
    getSmsCodeText: "获取验证码",
    inputBindPhoneText:"请输入绑定手机号",
    secondText:"秒",
    sendSmsCodeSuccessText:"发送成功，请注意查收",
    validatePhoneErrorText:"请输入正确的手机号码"
  },
  index: {
    content1Title: "精选直播",
    learnStudentCount: "最近在学",
    content1Btn: "马上看",
  },
  list: {
    filterAllText: "全部",
    filter1Title: "课程类别",
    filter2Title: "授课方式",
    filter3Title: "课程售价",
    teachingMethodTitle0: "直播",
    teachingMethodTitle1: "录播",
    teachingMethodTitle2: "小班课",
    priceTitle1: "￥0-50",
    priceTitle2: "￥50-100",
    priceTitle3: "￥100-500",
    priceTitle4: "￥500-1000",
    priceTitle5: "￥1000以上",
    priceTitle6: "免费",
    sortTitle1: "综合排序",
    sortTitle2: "发布时间",
    sortTitle3: "课程热度",
  },
  detail: {
    connect: "连麦",
    cancelConnect: "取消连麦",
    exitConnect: "退出连麦",
    teacherInfoTitle: "老师介绍",
    classesInfoTitle: "课程详情",
    chatTitle: "聊天",
    selectBagTitle1: "请选择课程包",
    selectBagTitle2: "以下课程课程包均含本课程",
    classesCount: "课程数量",
    openText: "展开",
    closeText: "收起",
    signUpBtnText: "立即报名",
    tabTitle2: "目录",
    tabTitle3: "讨论",
    noSelectClassesTip: "请选择当前课程或课程包",
    signSuccess: "报名成功",
    paySuccess: "支付成功",
    notLiveContent: "暂无直播内容",
    liveExpire: "直播过期",
    liveReading: "直播准备中",
    liveUrlExpire: "直播地址已失效",
    liveEnd: "当前直播已结束",
    tokenExpire: "token过期，请重新登录",
    exitLiveRoom: "您已被请出直播间",
    internalUnConnect: "您的网络已断开，点击刷新",
    connectClose: "连麦被关闭",
    connectSuccess: "连麦申通已通过",
    connectRefuse: "连麦被拒绝",
    connectOk: "已上麦",
    connectExit: "已下麦",
    isConfirmConnect: "确认申请连麦？",
    isCancelConnect: "确认取消申请连麦？",
    isExitConnect: "确认取消申请连麦？",
    isApply: "已申请",
    isCancel: "已申请",
    isExit: "已退出",
    sdkNotSupport: "sdk不支持当前观看方式"
  },
  my: {
    tab: {
      title1: "我的课程",
      title2: "我的考试",
      title3: "我的订单",
      title4: "个人设置",
    },
    tab2: {
      title1: "未完成",
      title2: "已完成",
      title3: "已过期",
    },
    classes: {
      live: "直播",
      living: "直播中",
      liveRecord: "录播",
      teacher: "讲师",
      liveTime: "直播时间",
      periodOfValidity: "有效期",
      goInLive: "进入直播",
      notStart: "未开始",
      isFinish: "已完成",
      goInClasses: "进入课程",
      isExpire: "已过期",
    },
    exam: {
      timeRange: "时间范围",
      deadline: "截止日期",
      LimitedTime: "限时",
      minute: "分钟",
      score: "分数",
      isExpire: "已过期",
      notStart: "未开始",
      goInExam: "进入考试",
      toBeEvaluated: "待评卷",
      showDetail: "查看详情"
    },
    order: {
      productBag: "产品包",
      price: "金额",
      time: "时间",
      statusText: "状态",
      orderNumber: "订单编号",
      status: {
        waitPay: "待支付",
        payFinish: "支付完成",
        cancelPay: "取消支付",
        expire: "超时关闭",
        toPay: "去支付",
        isPay: "已支付",
        notPay: "未支付",
        closeOrder:"交易关闭"
      },
      tabAll:"全部",
      tab1:"待支付",
      tab2:"已支付",
      tab3:"交易关闭"
    },
    user: {
      avatar: "头像",
      nickName: "昵称",
      gender: "性别",
      genderText1: "男",
      genderText2: "女",
      birthday: "生日",
      birthdayPlaceholder: "请选择生日",
      signature: "个性签名",
      signatureEmpty: "这个人很懒，什么都没有留下",
      examTip1: "本次考试持续时间",
      examTip2: "分钟，考试开始后不得中途退出，退出后系统将自动为您提交答卷。",
      examTipItem: "请注意，以下行为均视为中途退出：",
      examTipItem1: "1. 关闭当前页面",
      examTipItem2: "2. 刷新当前页面",
      examTipItem3: "3. 切换至其他tab",
      btnText1: "再等等吧",
      btnText2: "进入考试",
      uploadError: "上传失败，请重试",
      uploadAvatar: "请上传头像",
      enterNickName: "请输入昵称"
    }
  },
  exam: {
    surplusCount: "剩余题目",
    surplusTime: "剩余时间",
    submitText: "提交答案",
    typeText1: "单项选择题",
    typeText2: "不定项选择题",
    typeText3: "判断题",
    rightText: "正确",
    errorText: "错误",
    typeText4: "填空题",
    typeText5: "简答题",
    answer: "答案",
    notDataError: "数据获取失败，请退出重试",
    timeEndText: "考试时间结束，请交卷",
    confirmSubmitText: "提交答案后，将无法再次修改，系统将根据本次作答为您评定分数",
    confirmSubmitBtnText1: "确认提交",
    confirmSubmitBtnText2: "再等等吧",
    submitSuccess: "提交成功"
  },
  ...enLocale
};